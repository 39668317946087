import moment from 'moment'
import 'moment-duration-format'

interface DurationSettings {
  forceLength: boolean;
  precision: number;
  template: string;
  trim: boolean | 'left' | 'right';
}
interface DateTimeOptions {
  dateTimeIn?: string
  dateTimeOut?: string
}
// @ts-ignore
interface Duration extends moment.Duration {
  format: (template?: string, precision?: number, settings?: DurationSettings) => string;
}

export default class Time {
  static durationTime (startTime: string, stopTime: string, format?: string) {
    const mStartTime = moment(startTime)
    const mStopTime = moment(stopTime)
    const diff = moment.duration(mStopTime.diff(mStartTime)) as Duration
    return diff.format(format)
  }

  static dateTime (val: string, options: DateTimeOptions = { dateTimeIn: 'YYYY-MM-DD HH:mm:ss', dateTimeOut: 'DD/MM/YYYY HH:mm:ss' }) {
    const b = moment(val, options.dateTimeIn)
    const f = b.format(options.dateTimeOut)
    if (f === 'Invalid date') {
      return ''
    }
    return f
  }

  static TimeSinceNow (val: string) {
    if (val === '') {
      return 'N/A'
    } else {
      const now = moment()
      const b = moment(val, 'YYYY-MM-DD HH:mm:ss')
      const diff = Math.abs(b.diff(now))
      let ago = null
      if (diff < 60 * 1000) {
        return `${Math.floor(diff / (60 * 1000))} seconds ago`
      } else if (diff < 60 * 60 * 1000) {
        // @ts-ignore
        ago = moment.duration(diff).format('[00]:mm:ss [ago]')
      } else {
        // @ts-ignore
        ago = moment.duration(diff).format('d [day(s)] HH:mm:ss [ago]')
      }
      return ago.replace('-', '')
    }
  }
}
